import React, { createRef, useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import theme from "../../../theme";
import { KeyboardDoubleArrowDown } from "@mui/icons-material";
import BannerControl from "../../base/BannerControl";
import BannerContent from "../../base/BannerContent";
import Banner from "../../../types/banner";
import { useDispatch, useSelector } from "../../../store";
import { searchEvents } from "../../../slices/events";

interface Props {
  useIsVisible: (ref: any) => boolean;
  eventRef: React.RefObject<HTMLDivElement>;
}

const staticBanners = [
  {
    title: "Howloween 5K & 1-Mile Dog Walk",
    linkText: "Click here for details!",
    description: `Join us for the 10th Annual Howloween 5K and Dog Walk on Saturday, October 26th, 2024, at Parkside Place Park in North Wales, PA. This event consists of a 5K run/walk, and a 1-mile dog walk....PLUS, to celebrate our 10th Howloween, we are bringing back our wildly-popular Fall Fair with over 60 vendors!`,
    date: new Date(2024, 9, 26),
    src: "https://www.howloween5k.com/",
    address: {
      line1: "Parkside Place Park",
      city: "North Wales",
      postalCode: "",
      state: {
        id: 1,
        value: "PA",
      },
    },
  },
  {
    title: "10th Annual Howloween Special Raffle!",
    linkText: "Click Here to Enter",
    description: `We're raffling off a PAIR of sleek and stylish electric bikes in black and blue AND a Retrospec pet trailer! Whether you're biking with your furry friend or cruising solo, these prizes are perfect for all your adventures. The winners will be drawn at the 5k on Oct 26th. Attendance not required to win but shipping not included. Clink the link below for more details and to enter to win!`,
    src: "https://www.zeffy.com/en-US/ticketing/95f97bc0-eb99-4538-ae15-2a1bd93cd977",
  },
  {
    title: "Show your support of Home at Last with our seasonal swag!",
    linkText: "HAL Store",
    description: `FREE SHIPPING on all orders!`,
    src: "https://homeatlastswag.myshopify.com/",
  },
  {
    title: "Want to lend a hand?",
    linkText: "Help Us",
    description: `Fosters, volunteers, and supporters are an essential part of our rescue!`,
    src: "/help-us",
  },
];

const VideoBackground: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { searchResults } = useSelector((store) => store.events);

  const { useIsVisible, eventRef } = props;
  const [activeImage, setActiveImage] = useState(1);
  const [hover, setHover] = useState(false);
  const [banners, setBanners] = useState<Banner[]>([]);

  const bannerRef = createRef<HTMLDivElement>();

  const handleDownClick = () => {
    bannerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const updateBanners = useCallback(() => {
    const eventBanners: Banner[] =
      searchResults?.results?.map((e) => ({
        title: e.name,
        linkText: "Learn More",
        description: e.details,
        date: e.start,
        address: e.address,
        src: `/event/${e.id}`,
      })) || [];

    setBanners([...staticBanners, ...eventBanners]);
  }, [searchResults]);

  useEffect(() => {
    updateBanners();
  }, [updateBanners]);

  useEffect(() => {
    dispatch(
      searchEvents({
        search: {
          pageNumber: 1,
          pageSize: 3,
          filters: ["status:2", "status:3", "featured:1"],
          orderBy: "start",
          orderDirection: "Ascending",
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (!hover) {
      const interval = setInterval(() => {
        setActiveImage(activeImage < banners.length ? activeImage + 1 : 1);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, [activeImage, hover, banners.length]);

  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          bottom: 68,
          right: 15,
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.main,
          borderRadius: 25,
          display: useIsVisible(eventRef) ? "none" : { xs: "none", lg: "flex" },
          zIndex: 100,
          cursor: "pointer",
        }}
        onClick={handleDownClick}
      >
        <KeyboardDoubleArrowDown fontSize="large" />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,.5)",
        }}
      />
      <video
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        src="/static/videos/banner_video.mp4"
        autoPlay
        loop
        muted
        playsInline
      />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          bottom: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "stretch",
          color: "white",
        }}
      >
        <BannerControl activeImage={activeImage} setActiveImage={setActiveImage}>
          {banners.map((banner, index) => {
            return <BannerContent key={index} banner={banner} setHover={setHover} />;
          })}
        </BannerControl>
      </Box>
      <Box ref={bannerRef} sx={{ position: "relative", bottom: 138 }} />
    </Box>
  );
};

export default VideoBackground;
